<template>
  <div class="centered-screen">
    <div class="login-page">
      <div class="login-page__left">
        <LoginImage>
          <template #underLogo>
            <h1>Starting demo lesson</h1>
          </template>
        </LoginImage>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import LoginImage from "@/components/login/LoginImage";
import axios from "axios";
import store from "@/store/stores";

export default {
  name: "StartDemo",
  metaInfo: {
    title: "Starting demo lesson"
  },
  async mounted() {
    await this.setDemoToken();
  },
  methods: {
    async setDemoToken() {
      await axios
        .post(process.env.VUE_APP_AUTH_URL + "/oauth/token", {
          grant_type: "demo_session_grant",
          client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
          scope: ""
        })
        .then(data => {
          store.commit("userStore/setTokens", data.data);
          this.$router.replace({ name: "Home" });
        })
        .catch(e => {
          console.error(e);
        });
    },
    ...mapMutations("userStore", ["clearUserData", "setUserLogin"])
  },
  components: {
    LoginImage
  }
};
</script>

<style scoped lang="scss">
.login-page__left {
  width: 100%;

  ::v-deep .login-image {
    flex-wrap: wrap;
    flex-direction: column;
  }

  h1 {
    width: 100%;
    text-align: center;
    color: var(--white-color);
  }
}
</style>
